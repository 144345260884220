import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "tooltip"
    }}>{`Tooltip`}</h2>
    <ComponentCode name="Tooltip" component="tooltip" variation="tooltip" hasReactVersion="tooltip--default-bottom" hasVueVersion="tooltip--default-interactive-tootlip" hasAngularVersion="?path=/story/tooltip--basic" codepen="OGNWpy" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "icon-tooltip"
    }}>{`Icon tooltip`}</h2>
    <ComponentCode name="Icon tooltip" component="tooltip" variation="tooltip--icon" hasReactVersion="tooltipicon--default" hasVueVersion="tooltip--default-tootlip" codepen="LvNxWq" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "definition-tooltip"
    }}>{`Definition tooltip`}</h2>
    <ComponentCode name="Definition tooltip" component="tooltip" variation="tooltip--definition" hasReactVersion="tooltipdefinition--default" hasVueVersion="tooltip--default-definition-tootlip" codepen="pByRPr" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "documentation"
    }}>{`Documentation`}</h2>
    <ComponentDocs component="tooltip" mdxType="ComponentDocs" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      